
import { defineComponent } from 'vue'
import { AxiosError } from 'axios';

import Spinner from '@/components/misc/Spinner.vue'
import Center from '@/components/misc/Center.vue'
import Alert from '@/components/misc/Alert.vue'

export default defineComponent({
  name: 'Login',
  components: {
    Spinner,
    Center,
    Alert
  },
  data() {
    return {
      email: '',
      password: '',
      loading: false,
      error: '',
      goto: ''
    }
  },
  created() {
    
    if(this.$store.state.auth.isLoggedIn) {
      return this.$router.push('/profile');
    }

    const url = new URL(window.location.href);
    this.goto = url.searchParams.get('goto') as string;
  },
  methods: {
    login() {
      this.loading = true;
      this.$store.dispatch('login', {
        email: this.email,
        password: this.password
      }).then((result: any) => {
        this.$router.push(this.goto || '/');
      }).catch((error: AxiosError) => {

        if(!error.isAxiosError || !error.response) {
          this.error = 'Connection error';
        } else {
          this.error = error.response.data.detail;
        }

        this.loading = false;

      });
    }
  }
})
