import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["alert fade show", {'alert-dismissible': _ctx.dismissible}]),
    role: "alert"
  }, [
    _createElementVNode("strong", null, _toDisplayString(_ctx.modelValue), 1),
    (_ctx.dismissible)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          type: "button",
          class: "btn-close",
          "data-bs-dismiss": "alert",
          "aria-label": "Close",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)))
        }))
      : _createCommentVNode("", true)
  ], 2))
}