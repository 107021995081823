
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    dismissible: {
      type: Boolean,
      default: true
    }
  },
  emits: [
    'update:modelValue'
  ],
  methods: {
    handleClick() {
      this.$emit('update:modelValue', '')
    }
  }
})
